<template>
  <!--begin::Basic info-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-start" @click="backMenu">
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 text-white">Detail Sapa Tokoh Visit</h3>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9 strip-bg">
        <div class="d-flex flex-wrap py-5">
          <div class="flex-equal me-5">
            <div class="mb-10">
              <h5 class="mb-1">Lokasi:</h5>
              <div class="d-flex flex-wrap py-5">
                <div class="flex-equal me-5">
                  <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                    <tr>
                      <td class="text-gray-400">Nama Tokoh:</td>
                      <td class="text-gray-800">{{ detail.sapatokoh.name }}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Profil Ketokohan:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.profil }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Area Ketokohan:</td>
                      <td class="text-gray-800">{{ detail.sapatokoh.area }}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Alamat Tokoh:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.address }},
                        {{ detail.sapatokoh.territory_name }},
                        {{ detail.sapatokoh.kecamatan_name }} -
                        {{ detail.sapatokoh.kabupaten_name }},
                        {{ detail.sapatokoh.provinsi_name }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Nomor WA Tokoh:</td>
                      <td class="text-gray-800">{{ detail.sapatokoh.area }}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Jumlah Jama'ah / Basis:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.jumlah_basis }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Jumlah Potensi Pemilih 02:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.jumlah_pilih }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Resume Obrolah:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.resume }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Nama PIC Tokoh:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.name_pic }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Nomor WA PIC Tokoh:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.phone_pic }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Waktu Terkirim:</td>
                      <td class="text-gray-800">
                        {{ detail.sapatokoh.createdAt }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-400">Titik lokasi:</td>
                      <td class="text-gray-800"
                        @click="maps({ latitude: detail.sapatokoh.latitude, longitude: detail.sapatokoh.longitude })">
                        {{ detail.sapatokoh.latitude }},
                        {{ detail.sapatokoh.longitude }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-equal text-right">
            <div class="mb-10">
              <h5 class="mb-1">Relawan:</h5>
              <div class="d-flex flex-wrap py-5">
                <div class="flex-equal me-5">
                  <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                    <tr>
                      <td class="text-gray-400 min-w-175px w-175px">
                        Nama Relawan:
                      </td>
                      <td class="text-gray-800">
                        {{ detail.created_by.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-400">Terdaftar:</td>
                      <td class="text-gray-800">
                        {{ detail.created_by.createdAt }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Pertemuan:</h5>
              <img class="img-fluid" :src="detail.sapatokoh.image_first" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Saat Bersamaan:</h5>
              <img class="img-fluid" :src="detail.sapatokoh.image_second" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Saat Menerima Bingkisan:</h5>
              <img class="img-fluid" :src="detail.sapatokoh.image_third" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Bersama:</h5>
              <img class="img-fluid" :src="detail.sapatokoh.image_fourth" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Sapa Tokoh Visit",
  props: ["id"],
  setup(props) {
    setCurrentPageTitle("Sapa Tokoh Visit");

    const store = useStore();
    const router = useRouter();

    store.dispatch("getDetailSapatokohDashboard", props.id);

    const detail = computed(() => store.state.SapatokohDashboardModule.detail);

    const maps = (data) => {
      window.open(
        "https://maps.google.com/?q=" + data.latitude + "," + data.longitude,
        "_blank"
      );
    };

    const backMenu = () => {
      router.push({ name: "sapatokoh" });
    };

    return {
      maps,
      backMenu,
      detail,
    };
  },
});
</script>
